import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { LoginModel } from '../../shared/login.model';
import { CustomerService } from '../../core/services/customer.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import dayjs from 'dayjs';
import { SalesAttributionModel } from '../../shared/sales-attribution.model';
import { AttributionService } from './attribution.service';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthService {

  private formatErrors(error: any) {
    return throwError(error);
  }

  public loggedInStatusSubject = new BehaviorSubject<boolean>(this.hasCustomerToken());

  // loginExpiredStatusSubject is true when we have an actual customer, but their token has expired
  public loginExpiredStatusSubject = new BehaviorSubject<boolean>(false);

  public entitlementSubject = new BehaviorSubject<boolean>(false);

  public hasTokenSubject = new BehaviorSubject<boolean>(false);
  public logoutSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private jwtHelper: JwtHelperService,
    private attributionService: AttributionService,
    private customerService: CustomerService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  login(customer: LoginModel) {
    const post = this.customerService.login(customer).pipe(shareReplay());
    this.loggedInStatusSubject.next(true);
    post.subscribe(res => {
      this.setSession(res);
      this.customerService.getCustomer();
      this.checkEntitlement();
    });
    return post;
  }


  // this is called on inital page load; refresh tokens accordingly
  //  and perform the intital load of the customer and cart if 
  //  appropriate to seed the cache of these objects
  initializeUser() {
    this.checkToken(); // fix any expirations
    if (isPlatformBrowser(this.platformId)) {
      if (this.hasCustomerToken()) {
        this.loggedInStatusSubject.next(true);
        this.customerService.getCustomer();
        this.checkEntitlement();
        this.hasTokenSubject.next(true);
      } else if (this.hasGuestToken()) {
        this.hasTokenSubject.next(true);
      } else {
        this.registerGuest().subscribe(() => {
          this.hasTokenSubject.next(true);
        })
      }
    }
  }

  checkEntitlement = () => {
    if (environment.enableAdminTool && this.loggedInStatusSubject.getValue()) {
      this.customerService.getEntitlement('view_admin_ui').subscribe(res => {
        if (res) {
          this.entitlementSubject.next(true);
        } else {
          this.entitlementSubject.next(false);
        }
      }, (e) => {
        console.log(e);
        this.entitlementSubject.next(false);
      })
    }
  }

  checkToken() {
    const token = this.jwtHelper.decodeToken();

    // Scenario 1: Check to make sure there is a token and that the token is not expired
    if (token && !this.jwtHelper.isTokenExpired()) {
      let tokenExpiration = dayjs.unix(token.exp);

      // Get the difference in minutes between the token expiration and right now
      let tokenAgeInMinutes = tokenExpiration.diff(dayjs(), 'minutes');

      // If the token is older than 5 minutes then refresh it (note - 1440 minutes is 24 hours)
      if (tokenAgeInMinutes < 1435) {
        if (token.user.customer_id) {
          // This token belongs to a user. Let's refresh the user token
          this.refreshUserToken();
        } else if (token.user.guest_id) {
          // This token belongs to a guest. Let's refresh the guest token
          this.refreshGuestToken();
        }
      }
    } else if (token  && token.user.guest_id && this.jwtHelper.isTokenExpired()) {
      // Scenario 2: Check if there is a token present, the token is expired and the user is a guest
      this.refreshGuestToken();
    } else if (token && token.user.customer_id && this.jwtHelper.isTokenExpired()) {
      // Scenario 2: Check if there is a token present, the token is expired and the user is a customer
      this.logout();
    }
  }


  refreshUserToken() {
    const post = this.customerService.refreshToken().pipe(shareReplay());
    post.subscribe(res => this.setSession(res));
    return post;
  }

  refreshGuestToken() {
    const post = this.customerService.refreshGuestToken().pipe(shareReplay());
    post.subscribe(res => this.setSession(res));
    return post;
  }

  registerGuest() {
    const guestObs = this.customerService.registerGuest();
    guestObs.subscribe(res => {
      this.setSession(res)
    });
    return guestObs;
  }

  private setSession(authResult) {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.setItem('id_token', authResult.token);
    }
  }

  setAttribution(attribution: SalesAttributionModel) {
    if (attribution) {
      window.localStorage.setItem('attribution', attribution.attribution);
      window.localStorage.setItem('attribution_end', JSON.stringify(attribution.attributionEnd.valueOf()));
      window.localStorage.setItem('attribution_url', attribution.url);

      const decoded = this.jwtHelper.decodeToken();

      if (decoded?.user) {
        if (decoded.user.customer_id) {
          attribution.customerId = +decoded.user.customer_id;
        }
        if (decoded.user.guest_id) {
          attribution.guestId = decoded.user.guest_id;
        }
        if (attribution.guestId || attribution.customerId) {
          this.attributionService.saveAttribution(attribution).subscribe();
        }
      }

    }
  }
  


  public getAttribution(): SalesAttributionModel {
    let sa: SalesAttributionModel;
    const attr = window.localStorage.getItem('attribution');
    const attrEnd = dayjs(JSON.parse(window.localStorage.getItem('attribution_end')));
    if (attr && attrEnd) {
      if (dayjs().isAfter(attrEnd)) {
        this.clearAttribution();
      } else {
        sa = new SalesAttributionModel();
        sa.attribution = attr;
        sa.attributionEnd = attrEnd;
        sa.url = window.localStorage.getItem('attribution_url');
      }
    }
    return sa;
  }

  // we clear attribution 1) upon purchase 2) if we detect that its expired
  //  which is checked on item add && customer create
  public clearAttribution() {
    window.localStorage.removeItem('attribution');
    window.localStorage.removeItem('attribution_end');
    window.localStorage.removeItem('attribution_url');
  }


  public extendAttribution(newEndDt: dayjs.Dayjs) {
    window.localStorage.setItem('attribution_end', JSON.stringify(newEndDt.valueOf()));
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.removeItem('id_token');
      window.localStorage.removeItem('customer_id');
      // Note; do not clear attribution - rather, set it going forward
      this.loggedInStatusSubject.next(false);
      this.loginExpiredStatusSubject.next(false);
      this.registerGuest().subscribe(() => {
        this.customerService.clearCustomer();
        this.checkEntitlement();  
      });
    }
  }


  isLoggedIn(): Observable<boolean> {
    return this.loggedInStatusSubject.asObservable().pipe(catchError(this.formatErrors));;
  }

  customerLoginExpired(): Observable<boolean> {
    return this.loginExpiredStatusSubject.asObservable().pipe(catchError(this.formatErrors));;
  }

  public hasCustomerToken():boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const decoded = this.jwtHelper.decodeToken();
    return !!decoded?.user?.customer_id;
  }

  public hasGuestToken():boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const decoded = this.jwtHelper.decodeToken();
    return !!decoded?.user?.guest_id;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

}
