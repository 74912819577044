import { Injectable } from '@angular/core';
import { PricingApiService } from './pricing-api.service';
import { SalesAttributionModel } from '../../shared/sales-attribution.model';

@Injectable({ providedIn: 'root' })
export class AttributionService {

  constructor(    private apiService: PricingApiService, 
    ) { }

  saveAttribution(attribution:SalesAttributionModel) {
    return this.apiService.post('/attribution', attribution);
  }
}
